import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Billions.css";
import useAxios from 'axios-hooks';
import Card from "../components/Card";
import LoaderButton from "../components/LoaderButton";
import { Auth } from "aws-amplify";

export default function Billions(props) {

    const [isAuthenticated, userHasAuthenticated] = useState(false);
    // eslint-disable-next-line
    const [isAuthenticating, setIsAuthenticating] = useState(true);

    useEffect(() => {
        onLoad();
    }, []);

    async function onLoad() {
        try {
            await Auth.currentSession();
            userHasAuthenticated(true);
        }
        catch (e) {
            if (e !== 'No current user') {
                alert(e);
            }
        }

        setIsAuthenticating(false);

    }



    const [{ data, loading, error }] = useAxios(
        'https://api.hedgerow.io/mysfits'
    )

    if (loading) return <LoaderButton />
    if (error) return <p>Looks like we're down for maintenance. Please try again later.</p>


    return (
        <>
            <div className="row">
                <Card
                    index={0}
                    data={data ? data : true}
                />
                <Card
                    index={1}
                    data={data ? data : true}
                />
                <Card
                    index={2}
                    data={data ? data : true}
                />
            </div >

            <div className="row">
                <Card
                    index={3}
                    data={data ? data : true}
                />
                <Card
                    index={4}
                    data={data ? data : true}
                />
                <Card
                    index={5}
                    data={data ? data : true}
                />
            </div >
            <div>
                {isAuthenticated
                    ? <div className="row">
                        <Card
                            index={6}
                            data={data ? data : true}
                        />
                        <Card
                            index={7}
                            data={data ? data : true}
                        />
                        <Card
                            index={8}
                            data={data ? data : true}
                        />
                    </div >
                    : <>
                        <p>Please <Link to="/login">sign in</Link> to view additional data.</p>
                    </>
                }
            </div>


        </>



    )



}