import React from "react";
import "./Card.css";

export default function Card({
    index,
    data,
    className = "card",
    ...props
}) {
    console.log('Data:' + data)
    return (
        <div
            className={`card ${className}`}
            {...props}
        >
            <h3>{data.billionaires[index].name}</h3>
            <h4>{data.billionaires[index].company}</h4>
            <p className="description">{data.billionaires[index].description}</p>
            <br />
            <h4>Net Worth: {data.billionaires[index].networth}</h4>
            <h5>Top Holdings:</h5>
            <p className="holdings">{data.billionaires[index].topholdings[0]}&ensp;&ensp;{data.billionaires[index].topholdings[1]}&ensp;&ensp;{data.billionaires[index].topholdings[2]}</p>

            {props.children}
        </div>
    );
}