import React from "react";
import "./Home.css";
import Billions from "../containers/Billions";



export default function Home() {


    return (
        <div className="Home">
            <div className="lander">
                <div className="box">
                    <img className="logo" alt="Logo" src="/HedgerowV1.png" />
                </div>

                <div className="hero">
                    <h1 className="title">Welcome to Hedgerow.</h1>
                    <p>
                        We help retail investors keep up <br />
                        with smart money.
                    </p>

                    <div className="subsection">
                        <h3>Smart Money?</h3>
                        <p>Billionaire hedge fund managers amass their fortunes by thinking and acting differently than the average investor.
                            We provide our users analytics-driven insights into how these investors allocate their capital. </p>
                    </div>

                    <Billions />


                </div>


            </div>
            <p className="footer">The content is for informational purposes only, you <br /> should not construe
            any such information or other material as <br />legal, tax, investment,
            financial, or other advice.</p>
        </div>
    );
}